<template>
  <!-- 问题列表 -->
  <el-card class="question-item" v-for="item in questionList" :key="item.id">
    <div class="item-top">
      <div class="item-top-title">{{ item.title }}</div>
      <div class="item-top-time">{{ item.create_time }}</div>
    </div>
    <div class="item-content">{{ item.content }}</div>
    <div class="item-bottom">
      <div class="item-bottom-title"><svg-icon iconClass="huifu"></svg-icon>回复</div>
      <div class="item-bottom-content">{{ item.status == 0 ? '暂未回复...' : item.reply }}</div>
    </div>
  </el-card>

  <!-- 分页 -->
  <el-pagination layout="prev, pager, next" :total="questionTotal" hide-on-single-page @current-change="doChangePage"></el-pagination>

  <!-- 提问 -->
  <el-card>
    <el-form class="question-form" ref="ruleForm" :model="question" :rules="formRules">
      <el-form-item prop="title">
        <el-input v-model="question.title" placeholder="请输入标题" />
      </el-form-item>
      <el-form-item prop="content">
        <el-input v-model="question.content" :rows="5" type="textarea" placeholder="请输入问题" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="doSubmitQuestion">提交问题</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  
</template>

<script type="text/javascript">
import { myQuestion, reQuestion } from '@/api/user'
import SvgIcon from '@/components/svgicon'

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      // 查询条件
      query: {
        page: 1,
        limit: 10,
      },
      // 提问列表
      questionList: [],
      // 列表总条数
      questionTotal: null,
      // 我的提问
      question: {
        title: null,// 标题
        content: null,// 文本
      },
      // 表单验证
      formRules: {
        title: [{required: true, message: '标题不能为空', trigger: 'blur'}],
        content: [{required: true, message: '内容不能为空', trigger: 'blur'}],
      },
      
      
    }
  },
  created() {
    this.getQuestionList()
  },
  methods: {
    // 查询列表
    getQuestionList() {
      myQuestion(this.query).then(res => {
        if (res.code == 1) {
          this.questionList = res.data.list
          this.questionTotal = res.data.total
        }
      })
    },
    // 改变页数
    doChangePage(page) {
      this.query.page = page
      this.getQuestionList()
    },
    // 提交问题
    doSubmitQuestion() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          reQuestion(this.question).then(res => {
            if (res.code == 1) {
              this.$message.success('提交成功')
              this.$refs['ruleForm'].resetFields()
              this.getQuestionList()
            }
          })
        }else {
          return false
        }
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.el-card {
  padding: 10px;
  border-radius: 5px;
  cursor: auto;
}
.question-item {
  padding: 10px 15px;
  margin-bottom: 15px;
  
  .item-top {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .item-top-title {
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
      font-weight: bold;
    }
    .item-top-time {
      color: #999;
      font-size: 12px;
    }
  }
  .item-content {
    padding: 0 10px;
    margin-bottom: 15px;
  }
  .item-bottom {
    
    .item-bottom-title {
      color: #999;
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      .svg-icon {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    .item-bottom-content {
      color: #74797e;
      background-color: #f4f5f7;
      border-radius: 5px;
      padding: 10px;
    }
  }
}
.el-pagination {
  text-align: center;
  margin-bottom: 30px;
}
.question-form {

  & > .el-form-item:last-child {
    .el-button {
      background-color: $theme-color;
      border: none;
      float: right;
    }
  }
}
</style>
